import React from "react";
import "twin.macro";
import { graphql } from "gatsby";
import { PageLayout, CTA } from "../components";

const ContactLenses = ({ data: { strapiDsrContactLenses } }) => {
  return (
    <PageLayout {...strapiDsrContactLenses} flourish={1}>
      <CTA />
    </PageLayout>
  );
};

export default ContactLenses;

export const query = graphql`
  query contactLensesPageQuery {
    strapiDsrContactLenses {
      content
      subtitle
      intro
      title
    }
  }
`;
